import React from "react"
import { Navbar, Nav, Container } from "react-bootstrap"
import { FaDiscord, FaTwitch } from "react-icons/fa"

const Footer = () => {
	return (
		<Navbar id="footer" expand="lg" sticky="top" className="nav-fill w-100">
			<Nav className="mr-auto">
				<Container className="footerLinks">
                    <div className="faSocials">
                        <Nav.Link href="https://discord.gg/7Q8Dv68WHE" target="_blank" rel="noopener noreferrer">
                            <FaDiscord color='white' size='2.5em'/>
                        </Nav.Link>
                        <Nav.Link href="https://www.twitch.tv/adropofdelight" target="_blank" rel="noopener noreferrer">
                            <FaTwitch color='white' size='2.5em'/>
                        </Nav.Link>
                    </div>
                    <div className="footerInfo">
                        <Nav.Link href="/./" className="footerText" style={{ color: "white" }}> Home </Nav.Link>
                        <Nav.Link href="/tac/" className="footerText" style={{ color: "white" }}> Terms & Conditions </Nav.Link>
                    </div>    
                    <div className="footerInfo">
                        <Nav.Link href="mailto:contact@adropofdelight.org" className="footerText" style={{ color: "white" }}> Contact </Nav.Link>
                        <Nav.Link href="/coc/" className="footerText" style={{ color: "white" }}> Code of Conduct </Nav.Link>
                    </div>
				</Container>
			</Nav>
		</Navbar>
	)
}

export default Footer