import React from "react"
import { Navbar, Nav, Container } from "react-bootstrap"

//Smooth Scrolling
if (typeof window !== "undefined") {
	// eslint-disable-next-line global-require
	require("smooth-scroll")('a[href*="#"]')
}

const Header = () => {
	return (
		<Navbar id="header" expand="lg" sticky="top" className="nav-fill w-100">
			{/* <Navbar.Toggle aria-controls="basic-navbar-nav"/> */}
			{/* <Navbar.Collapse id="responsive-navbar-nav"> */}
			<Nav className="mr-auto">
				<Container className="headerLinks">
					<Nav.Link href="/./" className="headerText" style={{ color: "white" }}> HOME </Nav.Link>
					<Nav.Link href="/donate/" className="headerText" style={{ color: "white" }}> DONATION INFO </Nav.Link>
					<Nav.Link href="https://www.twitch.tv/adropofdelight" target="_blank" rel="noopener noreferrer" className="headerText" style={{ color: "white" }}> STREAM </Nav.Link>
					{/* <Nav.Link href="#link" className="headerText" style={{ color: "white" }}> CONTACT </Nav.Link> */}
				</Container>
			</Nav>
			{/* </Navbar.Collapse> */}
		</Navbar>
	)
}

export default Header
